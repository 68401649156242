<script setup lang="ts">
import { BaseButton } from '@legacy-studio/library';
import { UiDarkBBButton } from '~/src/shared/ui';
import { openTariffsModal } from '@/src/features/tariffs';
import { useDrawer } from '~/src/widgets/terminal/useDrawer';
import { TraderDrawers } from '~/src/widgets/terminal/enum';

const { openDrawer } = useDrawer();
</script>

<template>
	<div class="flex flex-col gap-[16px]">
		<UiDarkBBButton
			button-text="Пополнить"
			@click="openDrawer(TraderDrawers.TopUp)"
		/>

		<BaseButton
			color="alternative-dark"
			button-text="Вывод"
			outline
			@click="openDrawer(TraderDrawers.Withdrawal)"
		/>

		<BaseButton
			color="alternative-dark"
			button-text="История транзакций"
			outline
			@click="openDrawer(TraderDrawers.TransactionHistory)"
		/>

		<BaseButton
			color="alternative-dark"
			button-text="Тарифы"
			outline
			@click="openTariffsModal"
		/>
	</div>
</template>

<style scoped>

</style>
